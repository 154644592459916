import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Swatch = withStyles(() => ({
  root: ({ props: { color } }) => ({
    width: 24,
    minWidth: 24,
    height: 24,
    backgroundColor: color,
    color,
    padding: 0,
    borderRadius: 5,
    margin: 4,
    border: '2px solid rgba(0, 0, 0, 0.12)',
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: color,
      color,
    },
    '&$checked': {
      color,
    },
    '&$checked:hover': {
      backgroundColor: color,
    },
    '&::after': {
      content: '""',
      opacity: 0,
    },
  }),
  checked: ({ props: { color } }) => ({
    color,
    backgroundColor: color,
    boxShadow: `0 0 10px ${color}`,
    '&::after': {
      content: '""',
      opacity: 1,
      position: 'absolute',
      width: 12,
      height: 12,
      borderRadius: 50,
      background: '#FFF',
      transition: 'opacity 0.25s ease-in',
    },
  }),
  disabled: {
    backgroundColor: '#c5c5c5 !important',
    color: '#c5c5c5 !important',
    '&$checked': {
      backgroundColor: '#c5c5c5 !important',
      boxShadow: 'none',
    },
  },
}))(Radio);

export default Swatch;
