import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ColorsAndShapesModal from './ColorsAndShapesModal';


class ChooseShapes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
      addedTracks: [],
      changedTracks: new Set(),
    };

    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleAnimationCreate = this.handleAnimationCreate.bind(this);
    this.handleNoteClick = this.handleNoteClick.bind(this);
  }

  componentDidMount() {
    const {
      Events: {
        NOTE_CLICK,
      },
      EventBus,
      useNoteHoverCursor: enableHoveringNotes,
    } = window.MUSICEYES;

    enableHoveringNotes(true);

    EventBus.on(NOTE_CLICK, this.handleNoteClick);
  }

  handleDoneClick(data) {
    const {
      currentAnimation: {
        id,
      },
      changeAnimation,
    } = this.props;

    this.setState(({ addedTracks }) => ({
      isModalOpened: false,
      addedTracks: [
        ...addedTracks,
        id,
      ],
    }), () => changeAnimation({
      selectedAnimation: id,
      data,
    }));
  }

  handleAnimationCreate({ trackName, trackId }) {
    const {
      animations,
      createAnimation,
    } = this.props;

    this.setState(({ changedTracks }) => ({
      changedTracks: new Set([
        ...changedTracks,
        trackId,
      ]),
    }));

    const [{ id }] = Object.values(animations)
      .filter(({ name }) => name === trackName);

    createAnimation(id, {
      startTime: 0,
    });
  }

  handleNoteClick(event) {
    this.handleAnimationCreate(event);

    this.setState({
      isModalOpened: true,
    });
  }

  render() {
    const {
      noteColors,
      goToNextStep,
    } = this.props;

    const {
      changedTracks,
      isModalOpened,
    } = this.state;

    return (
      <Box display="flex" flexDirection="column">
        <Typography>
          Tap a note on the left to select a shape and color for that track (repeat for each of the tracks)
        </Typography>
        <ColorsAndShapesModal
          isOpened={isModalOpened}
          noteColors={noteColors}
          onDoneClick={this.handleDoneClick}
        />
        {changedTracks.size >= 3 && (
          <Box display="flex" justifyContent="center" paddingTop={2}>
            <Button variant="contained" color="primary" onClick={goToNextStep}>
              Next step
            </Button>
          </Box>
        )}
      </Box>
    );
  }
}


ChooseShapes.propTypes = {
  animations: PropTypes.shape({}).isRequired,
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  goToNextStep: PropTypes.func.isRequired,
  createAnimation: PropTypes.func.isRequired,
  changeAnimation: PropTypes.func.isRequired,
  currentAnimation: PropTypes.shape({
    id: PropTypes.number,
  }),
};

ChooseShapes.defaultProps = {
  currentAnimation: {
    id: null,
  },
};

export default ChooseShapes;
