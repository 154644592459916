import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  ruler: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: 30,
  },
  unit: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 100,
    borderLeft: '1px solid #C5C5C5',
    borderRight: '1px solid #C5C5C5',
    marginBottom: 10,
  },
  innerUnits: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  innerUnit: {
    minWidth: 20,
    height: 10,
    borderRight: '1px solid #C5C5C5',
  },
  label: {
    margin: '0 2px',
    fontSize: 14,
    fontWeight: 700,
  },
}));

const TIMESTAMP_TICKS = 5;

function getLabel(index) {
  const interval = index * TIMESTAMP_TICKS;
  const minutes = `0${Math.floor(interval / 60)}`.slice(-2);
  const seconds = `0${interval % 60}`.slice(-2);

  return `${minutes}:${seconds}`;
}

function Ruler({
  width,
}) {
  const classes = useStyles();

  return (
    <div className={classes.ruler}>
      {new Array(width).fill(false).map((_, index) => (
        <div className={classes.unit} key={index}>
          <div className={classes.innerUnits}>
            {new Array(width / TIMESTAMP_TICKS - 1).fill(false).map((_, innerIndex) => (
              <div
                className={classes.innerUnit}
                key={innerIndex}
              />
            ))}
          </div>
          <div className={classes.label}>
            {getLabel(index)}
          </div>
        </div>
      ))}
    </div>
  );
}

Ruler.propTypes = {
  width: PropTypes.number,
};

Ruler.defaultProps = {
  width: 100,
};

export default Ruler;
