function getActiveTabHistory(state) {
  const activeTab = state.tabs.activeTab === 'color-palette'
    ? 'settings'
    : state.tabs.activeTab;

  return state[activeTab] ? state[activeTab] : {};
}

export const historySelectors = {
  getPastHistory: (state) => getActiveTabHistory(state).past || [],
  getFutureHistory: (state) => getActiveTabHistory(state).future || [],
};
