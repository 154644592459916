import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from '@material-ui/core';
import AnimationsPanel from '../../Animations/components/AnimationsPanel';

const useStyles = makeStyles(({ palette, typography }) => ({
  modalContainer: {
    position: 'absolute !important',
  },
  modal: {
    position: 'absolute',
    left: 'calc(50% - 250px)',
    bottom: '25%',
    background: '#FFF',
    width: 500,
    borderRadius: 12,
    padding: 12,
  },
  backdrop: {
    position: 'relative',
    height: '100%',
    color: '#FFF',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      bottom: 0,
      borderLeft: '2px dashed rgba(255, 255, 255, 0.5)',
    },
  },
  backdropLabel: {
    ...typography.body1,
    background: palette.background.default,
    color: palette.text.primary,
    borderRadius: '4px',
    padding: '6px 16px',
    margin: '6px',
    zIndex: 1,
  },
}));

function ContrastModal({
  afterPlay,
  beforePlay,
  isOpened,
  onChange,
  onDoneClick,
  onPlay,
  title,
}) {
  const classes = useStyles();

  return (
    <Modal
      open={isOpened}
      className={classes.modalContainer}
      container={document.querySelector('.Player')}
      BackdropComponent={() => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.backdrop}
        >
          <Box className={classes.backdropLabel}>
            Past
          </Box>
          <Box className={classes.backdropLabel}>
            Present
          </Box>
          <Box className={classes.backdropLabel}>
            Future
          </Box>
        </Box>
      )}
    >
      <Box className={classes.modal}>
        <Typography variant="h6" color="primary">
          { title }
        </Typography>
        <Box paddingTop={2} paddingBottom={2}>
          <Divider variant="fullWidth" />
        </Box>
        <Box>
          <AnimationsPanel
            beforePlay={beforePlay}
            onPlay={onPlay}
            afterPlay={afterPlay}
            onChange={onChange}
          />
        </Box>
        <Box paddingTop={2} paddingBottom={2}>
          <Divider variant="fullWidth" />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={onDoneClick}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

ContrastModal.propTypes = {
  onDoneClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  beforePlay: PropTypes.string,
  onPlay: PropTypes.string,
  afterPlay: PropTypes.string,
  title: PropTypes.string,
};

ContrastModal.defaultProps = {
  title: '',
  beforePlay: '',
  onPlay: '',
  afterPlay: '',
};

export default ContrastModal;
