import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { bindActionCreators } from 'redux';
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  FormLabel,
  Input,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/Restore';
import { withStyles } from '@material-ui/styles';
import {
  restoreRevision,
  saveRevision,
} from '../store/actions/revisions';
import {
  revisionsSelectors,
} from '../store/selectors/revisions';
import { historySelectors } from '../store/selectors/history';

const SnapshotsPanelDrawer = withStyles(() => ({
  paper: {
    maxWidth: 400,
  },
}))(Drawer);

const styles = {
  list: {
    display: 'flex',
    flexGrow: 1,
  },
  primaryButton: {
    color: '#FFF',
  },
  inputLabel: {
    fontWeight: 700,
    paddingRight: 12,
  },
  buttonIcon: {
    marginRight: 8,
  },
};

function formatTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  const fullDate = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
  const time = `${date.getHours()}:${date.getMinutes()}`;

  return `${fullDate} at ${time}`;
}

class SnapshotsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snapshotName: `Snapshot #${props.revisionsList.length + 1}`,
      selectedSnapshotTimestamp: '',
    };

    this.handleSnapshotSave = this.handleSnapshotSave.bind(this);
    this.handleSnapshotSelection = this.handleSnapshotSelection.bind(this);
    this.handleSnapshotNameChange = this.handleSnapshotNameChange.bind(this);
    this.handleSnapshotRestore = this.handleSnapshotRestore.bind(this);
  }

  handleSnapshotNameChange({ target }) {
    this.setState({ snapshotName: target.value });
  }

  handleSnapshotSave() {
    const {
      saveRevision,
      revisionsList,
      currentSnapshot,
    } = this.props;

    const {
      snapshotName,
    } = this.state;

    saveRevision({
      ...currentSnapshot,
      name: snapshotName,
      timestamp: Date.now(),
    });

    this.setState({
      snapshotName: `Snapshot #${revisionsList.length + 2}`,
    });
  }

  handleSnapshotSelection(event, timestamp) {
    this.setState({
      selectedSnapshotTimestamp: parseInt(timestamp, 10),
    });
  }

  handleSnapshotRestore() {
    const {
      selectedSnapshotTimestamp,
    } = this.state;

    const {
      revisionsList,
      restoreRevision,
    } = this.props;

    const snapshot = revisionsList.find(
      (revision) => revision.timestamp === selectedSnapshotTimestamp,
    );

    restoreRevision(snapshot);
  }

  render() {
    const {
      classes,
      isOpened,
      onClose,
      revisionsList,
    } = this.props;

    const {
      selectedSnapshotTimestamp,
      snapshotName,
    } = this.state;

    return (
      <SnapshotsPanelDrawer
        anchor="right"
        open={isOpened}
        onClose={onClose}
      >
        <Box p={2} display="flex" flexDirection="column" flexGrow="1">
          <Box p={2}>
            <Typography variant="h6" color="primary">
              Save a snapshot
            </Typography>
            <Typography>
              Save current version of the animation as a snapshot so you can retreive it later.
            </Typography>
            <Box>
              <FormLabel>
                <Typography color="textPrimary" display="inline" className={classes.inputLabel}>
                  Name:
                </Typography>
                <Input
                  value={snapshotName}
                  onChange={this.handleSnapshotNameChange}
                />
              </FormLabel>
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.primaryButton}
                  onClick={this.handleSnapshotSave}
                  disabled={!snapshotName}
                >
                  <SaveIcon className={classes.buttonIcon} />
                  Save
                </Button>
                <Button
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box p={2} display="flex" flexDirection="column" flexGrow="1">
            <Typography variant="h6" color="primary">
              Load a snapshot
            </Typography>
            <Typography>
              Restore a previous snapshot of your animation.
            </Typography>
            <Box display="flex" flexDirection="column" flexGrow="1">
              {revisionsList.length > 0 ? (
                <List className={classes.list}>
                  <RadioGroup
                    name="selectedSnapshot"
                    value={selectedSnapshotTimestamp}
                    onChange={this.handleSnapshotSelection}
                  >
                    {revisionsList.map((snapshot) => (
                      <ListItem key={snapshot.timestamp}>
                        <FormControlLabel
                          value={snapshot.timestamp}
                          control={<Radio color="primary" />}
                          label={(
                            <Box display="flex" flexDirection="column">
                              <strong>{snapshot.name}</strong>
                              {formatTimestampToDate(snapshot.timestamp)}
                            </Box>
                          )}
                        />
                        <Divider />
                      </ListItem>
                    ))}
                  </RadioGroup>
                </List>
              ) : (
                <Box display="flex" flexGrow="1" alignItems="center" justifyContent="center">
                  <Typography color="textSecondary">No snapshots available</Typography>
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSnapshotRestore}
                  className={classes.primaryButton}
                >
                  <RestoreIcon className={classes.buttonIcon} />
                  Restore
                </Button>
                <Button
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </SnapshotsPanelDrawer>
    );
  }
}

SnapshotsPanel.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  revisionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveRevision: PropTypes.func.isRequired,
  restoreRevision: PropTypes.func.isRequired,
  currentSnapshot: PropTypes.shape({}),
  classes: PropTypes.shape({
    buttonIcon: PropTypes.string,
    inputLabel: PropTypes.string,
    list: PropTypes.string,
    primaryButton: PropTypes.string,
    snapshotsPanel: PropTypes.string,
  }).isRequired,
};

SnapshotsPanel.defaultProps = {
  isOpened: false,
  currentSnapshot: null,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveRevision,
  restoreRevision,
  undoHistory: ActionCreators.undo,
  redoHistory: ActionCreators.redo,
}, dispatch);

const mapStateToProps = (state) => ({
  pastHistory: historySelectors.getPastHistory(state),
  futureHistory: historySelectors.getFutureHistory(state),
  currentSnapshot: revisionsSelectors.getCurrentSnapshot(state),
  revisionsList: revisionsSelectors.getRevisionsList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SnapshotsPanel));
