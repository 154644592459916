import {
  REMOVE_ACTIVE_ANIMATION,
  REMOVE_ACTIVE_TRIGGER,
  SELECT_ANIMATION,
  SELECT_TRIGGER,
  SELECT_MOMENT,
  SELECT_CAMERA,
  UPDATE_TIMELINE,
} from '../actions/timeline';

import {
  REMOVE_MOMENT,
} from '../actions/moments';

import {
  REMOVE_ANIMATION,
} from '../actions/animations';

import {
  REMOVE_TRIGGER,
} from '../actions/triggers';

const initialState = {
  activeItem: null,
  selectedTrigger: {
    id: null,
    section: null,
  },
  selectedAnimation: null,
  selectedMoment: null,
  selectedCamera: null,
};

const timelineReducer = (state = initialState, action) => {
  const {
    payload,
  } = action;
  switch (action.type) {
    case SELECT_TRIGGER: {
      const {
        id,
        key,
      } = payload;

      return {
        ...state,
        selectedTrigger: {
          id,
          section: key,
        },
      };
    }
    case SELECT_ANIMATION:
      return {
        ...state,
        selectedAnimation: payload,
      };
    case SELECT_MOMENT:
      return {
        ...state,
        selectedMoment: payload,
      };
    case SELECT_CAMERA:
      return {
        ...state,
        selectedCamera: payload,
      };
    case REMOVE_ACTIVE_TRIGGER: {
      return {
        ...state,
        selectedTrigger: Object.keys(state.triggers)[0],
      };
    }
    case REMOVE_ACTIVE_ANIMATION: {
      const {
        animations,
      } = state;

      return {
        ...state,
        animations: Object.entries(animations).reduce((obj, [key, value]) => {
          const items = value.items.filter(({ id }) => id !== payload);

          return {
            ...obj,
            [key]: {
              ...value,
              items,
            },
          };
        }, {}),
      };
    }
    case REMOVE_MOMENT: {
      return {
        ...state,
        selectedMoment: null,
      };
    }
    case REMOVE_ANIMATION: {
      return {
        ...state,
        selectedAnimation: null,
      };
    }
    case REMOVE_TRIGGER: {
      return {
        ...state,
        selectedTrigger: {
          id: null,
          section: null,
        },
      };
    }
    case UPDATE_TIMELINE: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default timelineReducer;
