import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@material-ui/core';
import CollapsiblePanel from '../../../../common/CollapsiblePanel';
import Slider from '../../../../common/Slider';
import LayerSpeedPanel from './LayerSpeedPanel';

function MotionPanels({
  activePanels,
  values: {
    scrollSpeed = {},
    layerSpeed = {},
    magneticEffect = {},
  },
  onChange,
  onPanelCollapse,
  layers,
  disabled,
  onTriggerClick,
}) {
  return (
    <Box>
      <CollapsiblePanel
        onClick={() => onTriggerClick('scrollSpeed')}
        title="Scroll speed"
        isExpanded={activePanels.includes('scrollSpeed')}
        handleChange={() => onPanelCollapse('scrollSpeed')}
        disabled={disabled}
      >
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid item>
            <Slider
              value={scrollSpeed.speed}
              onChange={(speed) => onChange('scrollSpeed', { speed })}
              title="Speed"
              labelLeft="Fast"
              labelRight="Slow"
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item>
            <Slider
              title="Transition"
              labelLeft="Fast"
              labelRight="Slow"
              value={scrollSpeed.transitionDuration}
              onChange={(transitionDuration) => onChange('scrollSpeed', { transitionDuration })}
              valueLabelDisplay="off"
            />
          </Grid>
        </Grid>
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('layerSpeed')}
        title="Layer speed"
        isExpanded={activePanels.includes('layerSpeed')}
        handleChange={() => onPanelCollapse('layerSpeed')}
        disabled={disabled}
      >
        <LayerSpeedPanel
          layers={layers}
          value={layerSpeed}
          onChange={(speed) => onChange('layerSpeed', speed)}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('magneticEffect')}
        title="Magnetic effect"
        isExpanded={activePanels.includes('magneticEffect')}
        handleChange={() => onPanelCollapse('magneticEffect')}
        disabled={disabled}
      >
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid item>
            <Slider
              title="Intensity"
              value={magneticEffect.intensity}
              onChange={(intensity) => onChange('magneticEffect', { intensity })}
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item>
            <Slider
              title="Transition"
              labelLeft="Fast"
              labelRight="Slow"
              value={magneticEffect.transitionDuration}
              onChange={(transitionDuration) => onChange('magneticEffect', { transitionDuration })}
              valueLabelDisplay="off"
            />
          </Grid>
        </Grid>
      </CollapsiblePanel>
    </Box>
  );
}

MotionPanels.propTypes = {
  values: PropTypes.shape({
    scrollSpeed: PropTypes.object,
    layerSpeed: PropTypes.object,
    magneticEffect: PropTypes.object,
  }),
  activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPanelCollapse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onTriggerClick: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
};

MotionPanels.defaultProps = {
  values: {
    scrollSpeed: {
      startTime: 0,
      speed: 50,
      transitionDuration: 5,
    },
    layerSpeed: {
      startTime: 0,
      layer: 0,
      speed: 0,
      transitionDuration: 5,
    },
    magneticEffect: {
      startTime: 0,
      intensity: 50,
      transitionDuration: 5,
    },
  },
};

export default MotionPanels;
