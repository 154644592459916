import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Typography,
} from '@material-ui/core';
import CollapsiblePanel from '../../../common/CollapsiblePanel';
import ListenPiece from '../components/ListenPiece';
import ChooseShapes from '../components/ChooseShapes';
import Contrast from '../components/Contrast';
import NoteMovement from '../components/NoteMovement';
import VisualEmphasis from '../components/VisualEmphasis';
import CustomizeBackground from '../components/CustomizeBackground';
import { settingsSelectors } from '../../../store/selectors/settings';
import { animationsSelectors } from '../../../store/selectors/animations';
import triggersSelectors from '../../../store/selectors/triggers';
import timelineSelectors from '../../../store/selectors/timeline';
import {
  createAnimation,
  createTrigger,
} from '../../../store/actions/timeline';
import {
  changeAnimation,
} from '../../../store/actions/animations';
import {
  changeTrigger,
} from '../../../store/actions/triggers';

class AnimationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
    };
  }

  render() {
    const {
      activeStep,
    } = this.state;

    const {
      animations,
      backgroundColors,
      changeAnimation,
      createAnimation,
      noteColors,
      selectedAnimation,
      currentAnimation,
      createTrigger,
      changeTrigger,
      currentTrigger,
      onWizardDone,
    } = this.props;

    return (
      <Box>
        <Typography variant="h6" color="primary">
          Animation wizard
        </Typography>
        <Box>
          <CollapsiblePanel
            title="Listen to the piece"
            isExpanded={activeStep === 0}
          >
            <ListenPiece
              onStepDone={() => this.setState({
                activeStep: 1,
              })}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            title="Choose shapes and colors"
            isExpanded={activeStep === 1}
          >
            <ChooseShapes
              animations={animations}
              createAnimation={createAnimation}
              changeAnimation={changeAnimation}
              currentAnimation={currentAnimation}
              noteColors={noteColors}
              goToNextStep={() => this.setState({
                activeStep: 2,
              })}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            title="Contrast past / present / future"
            isExpanded={activeStep === 2}
          >
            <Contrast
              animations={animations}
              createAnimation={createAnimation}
              changeAnimation={changeAnimation}
              currentAnimation={currentAnimation}
              noteColors={noteColors}
              goToNextStep={() => this.setState({
                activeStep: 3,
              })}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            title="Customize note movement"
            isExpanded={activeStep === 3}
          >
            <NoteMovement
              createTrigger={createTrigger}
              changeTrigger={changeTrigger}
              currentTrigger={currentTrigger}
              onStepDone={() => this.setState({
                activeStep: 4,
              })}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            title="Add visual emphasis"
            isExpanded={activeStep === 4}
          >
            <VisualEmphasis
              createTrigger={createTrigger}
              changeTrigger={changeTrigger}
              currentTrigger={currentTrigger}
              onStepDone={() => this.setState({
                activeStep: 5,
              })}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            title="Customize background"
            isExpanded={activeStep === 5}
          >
            <CustomizeBackground
              createTrigger={createTrigger}
              changeTrigger={changeTrigger}
              currentTrigger={currentTrigger}
              color={0}
              style={null}
              backgroundColors={backgroundColors}
              onStepDone={onWizardDone}
            />
          </CollapsiblePanel>
        </Box>
      </Box>
    );
  }
}

AnimationWizard.propTypes = {
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  backgroundColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  createAnimation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  animations: animationsSelectors.getAnimations(state),
  currentAnimation: animationsSelectors.getCurrentAnimation(state),
  currentTrigger: triggersSelectors.getCurrentTrigger(state),
  noteColors: settingsSelectors.getNoteColors(state),
  backgroundColors: settingsSelectors.getBackgroundColors(state),
  selectedAnimation: timelineSelectors.getSelectedAnimation(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeAnimation,
  createAnimation,
  createTrigger,
  changeTrigger,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AnimationWizard);
