import React from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { withStyles } from '@material-ui/core/styles';

const StyledExpansionPanel = withStyles(() => ({
  root: {
    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: 'transparent',
  },
}))(ExpansionPanel);

const PanelTitle = withStyles(({ palette }) => ({
  root: {
    flexDirection: 'row-reverse',
    color: palette.text.secondary,
    border: 0,
    paddingLeft: 8,
  },
  expandIcon: {
    paddingLeft: 0,
    color: '#F2F2F2',
  },
  content: {
    padding: '0 12px',
  },
  expanded: {
    color: palette.primary[500],
  },
}))(ExpansionPanelSummary);

const PanelDetails = withStyles(() => ({
  root: {
    paddingLeft: 36,
  },
}))(ExpansionPanelDetails);


function CollapsiblePanel({
  isExpanded,
  handleChange,
  children,
  title,
  disabled,
  onClick,
}) {
  const expanded = !disabled && isExpanded;
  return (
    <StyledExpansionPanel
      onClick={onClick}
      expanded={expanded}
      onChange={handleChange}
      disabled={disabled}
      TransitionProps={{ unmountOnExit: true }}
    >
      <PanelTitle
        expandIcon={isExpanded ? <CheckCircleIcon /> : <FiberManualRecordIcon />}
      >
        {title}
      </PanelTitle>
      <PanelDetails>
        {children}
      </PanelDetails>
    </StyledExpansionPanel>
  );
}

CollapsiblePanel.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  isExpanded: PropTypes.bool,
  handleChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CollapsiblePanel.defaultProps = {
  handleChange: () => {},
  isExpanded: false,
  disabled: false,
  onClick: null,
};

export default CollapsiblePanel;
