import React from 'react';
import PropTypes from 'prop-types';
import { useId } from 'react-id-generator';
import {
  FormControl,
  InputLabel,
  Select as MaterialSelect,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const StyledSelect = withStyles(() => ({
  root: {
    height: 'auto',
  },
}))(MaterialSelect);

function Select({
  value,
  options,
  label,
  onChange,
  disabled,
}) {
  const [htmlId] = useId();

  return (
    <FormControl>
      <InputLabel shrink htmlFor={htmlId}>
        {label}
      </InputLabel>
      <StyledSelect
        multiple
        native
        value={[value]}
        onChange={({ target }) => onChange(target.value)}
        inputProps={{
          id: htmlId,
        }}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option.key} value={option.key}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

Select.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  value: null,
  disabled: false,
};

export default Select;
