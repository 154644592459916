import { lightBlue } from '@material-ui/core/colors';

export default {
  root: {
    color: lightBlue[400],
    padding: 0,
    border: 0,
    '&$expanded': {
      minHeight: 0,
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  content: {
    fontWeight: 700,
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    '&$expanded': {
      color: lightBlue[400],
      transform: 'none',
    },
  },
};
