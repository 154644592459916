import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import LayersList from '../../../../common/LayersList';
import Slider from '../../../../common/Slider';


function NoteGlowPanel({
  values,
  onChange,
  layers,
}) {
  return (
    <Grid
      container
      direction="column"
      justify="space-around"
      alignItems="stretch"
    >
      <Grid item>
        <Slider
          title="Intensity"
          value={values.intensity}
          onChange={(intensity) => onChange({ intensity })}
          min={0}
          max={100}
          step={1}
        />
      </Grid>
      <Grid item>
        <Slider
          title="Transition"
          labelLeft="Fast"
          labelRight="Slow"
          value={values.transitionDuration}
          onChange={(transitionDuration) => onChange({ transitionDuration })}
          valueLabelDisplay="off"
        />
      </Grid>
      <Grid item>
        <LayersList
          value={values.layer}
          label="Layer"
          onChange={(layer) => onChange({ layer })}
          layers={layers}
        />
      </Grid>
    </Grid>
  );
}

NoteGlowPanel.propTypes = {
  values: PropTypes.shape({
    layer: PropTypes.number,
    intensity: PropTypes.number,
    transitionDuration: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NoteGlowPanel.defaultProps = {
  values: {
    layer: null,
    intensity: null,
    transitionDuration: null,
  },
};

export default NoteGlowPanel;
