import timelineSelectors from './timeline';

export const momentsSelectors = {
  getMoments: (state) => state.moments.present,
  getSelectedMoment: (state) => {
    const selectedMomentId = timelineSelectors.getSelectedMoment(state);
    return Number.isInteger(selectedMomentId)
    ? state.moments.present.moments.items.find(({ id }) => id === selectedMomentId)
    : undefined;
  },
};
