// eslint-disable-next-line import/prefer-default-export
export const getCurrentTime = (currentTime) => {
  const minutes = `0${Math.floor(window.MUSICEYES.getCurrentTime() / 60)}`.slice(-2);
  const seconds = `0${Math.floor(currentTime % 60)}`.slice(-2);

  return currentTime ? `${minutes}:${seconds}` : null;
};

export const ignoreTriggersList = (tabName) => {
  const {
    setCameraAngle,
    updateIgnoreTriggersList,
  } = window.MUSICEYES;

  if (tabName !== 'camera' && tabName !== 'moments') {
    updateIgnoreTriggersList([
      'CAMANGL',
      'CAMROLL',
    ]);

    setCameraAngle({
      pos: {
        x: 0,
        y: 0,
        z: 500,
      },
      target: {
        x: 0,
        y: 0,
        z: 0,
      },
      roll: 0,
      phraseZoomFactor: 0,
    });
  } else {
    updateIgnoreTriggersList([]);
  }
};
