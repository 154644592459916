import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import Select from '../../../../common/Select';
import Slider from '../../../../common/Slider';


const particleFieldOptions = [
  { key: 'default', label: 'None' },
  { key: 'a', label: 'Underwater' },
  { key: 'b', label: 'Snow' },
  { key: 'c', label: 'Starfield' },
  { key: 'c2', label: 'Starry Sky' },
  { key: 'd', label: 'Rain' },
  { key: 'e', label: 'Colorful dots' },
  { key: 'f', label: 'Dust' },
];

function BackgroundParticlePanel({
  onChange,
  values,
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          value={values.particles}
          options={particleFieldOptions}
          onChange={(particles) => onChange({ particles })}
          label="Particles"
        />
      </Grid>
      <Grid item xs={12}>
        <Slider
          title="Transition"
          labelLeft="Fast"
          labelRight="Slow"
          value={values.transitionDuration}
          onChange={(transitionDuration) => onChange({ transitionDuration })}
          valueLabelDisplay="off"
        />
      </Grid>
    </Grid>
  );
}

BackgroundParticlePanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    particles: PropTypes.string,
    transitionDuration: PropTypes.number,
  }),
};

BackgroundParticlePanel.defaultProps = {
  values: {
    particles: null,
    transitionDuration: null,
  },
};

export default BackgroundParticlePanel;
