import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import Select from '../../../../common/Select';
import Slider from '../../../../common/Slider';


const shimmerOptions = [
  { key: 'default', label: 'None' },
  { key: 'a', label: 'Very gentle' },
  { key: 'b', label: 'Gentle' },
  { key: 'c', label: 'Medium' },
  { key: 'd', label: 'Strong' },
  { key: 'e', label: 'Very strong' },
  { key: 'f', label: 'Fast' },
  { key: 'g', label: 'From left' },
  { key: 'h', label: 'From right' },
  { key: 'i', label: 'From bottom' },
];

function BackgroundShimmerPanel({
  onChange,
  values,
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          value={values.shimmer}
          options={shimmerOptions}
          onChange={(shimmer) => onChange({ shimmer })}
          label="Shimmer"
        />
      </Grid>
      <Grid item xs={12}>
        <Slider
          title="Transition"
          labelLeft="Fast"
          labelRight="Slow"
          value={values.transitionDuration}
          onChange={(transitionDuration) => onChange({ transitionDuration })}
          valueLabelDisplay="off"
        />
      </Grid>
    </Grid>
  );
}

BackgroundShimmerPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    shimmer: PropTypes.string,
    transitionDuration: PropTypes.number,
  }),
};

BackgroundShimmerPanel.defaultProps = {
  values: {
    shimmer: null,
    transitionDuration: null,
  },
};

export default BackgroundShimmerPanel;
