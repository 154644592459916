import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import Select from '../../../../common/Select';
import ColorPicker from '../../../../common/ColorPicker';
import Slider from '../../../../common/Slider';
import backgroundColorStyles from '../../../../common/BackgroundColorStyles';

function BackgroundColorPanel({
  backgroundColors,
  onChange,
  values,
}) {
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <ColorPicker
            colors={backgroundColors}
            value={values.color}
            onSelectionChange={(color) => onChange({ color })}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            value={values.style}
            options={backgroundColorStyles}
            onChange={(style) => onChange({ style })}
            label="Style"
          />
        </Grid>
        <Grid item xs={12}>
          <Slider
            title="Transition"
            labelLeft="Fast"
            labelRight="Slow"
            value={values.transitionDuration}
            onChange={(transitionDuration) => onChange({ transitionDuration })}
            valueLabelDisplay="off"
          />
        </Grid>
      </Grid>
    </div>
  );
}

BackgroundColorPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    color: PropTypes.number,
    transitionDuration: PropTypes.number,
    style: PropTypes.string,
  }),
  backgroundColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

BackgroundColorPanel.defaultProps = {
  values: {
    color: 0,
    transitionDuration: 5,
    style: null,
  },
};

export default BackgroundColorPanel;
