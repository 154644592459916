import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const DetailsSection = withStyles(() => ({
  root: {
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}))(Box);

export default DetailsSection;
