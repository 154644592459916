import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import timelineSelectors from '../../../store/selectors/timeline';
import {
  changeMoment,
  removeMoment,
} from '../../../store/actions/moments';
import { momentsSelectors } from '../../../store/selectors/moments';
import { getCurrentTime } from '../../../common/Utils';

const styles = ({ palette }) => ({
  wrapper: {
    height: '100%',
  },
  removeButton: {
    background: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      background: palette.error.dark,
    },
  },
  formGroup: {
    maxHeight: 250,
    marginBottom: 32,
    flexWrap: 'nowrap',
    overflow: 'auto',
  },
});

const conceptsOptions = [
  { key: '40', label: 'Articulation: Accents' },
  { key: '21', label: 'Articulation: Legato' },
  { key: '20', label: 'Articulation: Staccato' },
  { key: '2', label: 'Dynamics: Crescendo' },
  { key: '3', label: 'Dynamics: Decrescendo' },
  { key: '4', label: 'Dynamics: Forte' },
  { key: '5', label: 'Dynamics: Piano' },
  { key: '6', label: 'Dynamics: Subito' },
  { key: '36', label: 'Form: Coda' },
  { key: '16', label: 'Form: Concerto' },
  { key: '34', label: 'Form: Fermata' },
  { key: '14', label: 'Form: Passacaglia' },
  { key: '33', label: 'Form: Phrasing' },
  { key: '12', label: 'Form: Rondeau' },
  { key: '15', label: 'Form: Scherzo' },
  { key: '13', label: 'Form: Sonata form' },
  { key: '45', label: 'Form: Waltz' },
  { key: '1', label: 'Harmony: Bassline' },
  { key: '35', label: 'Harmony: Harmonizing in 3rds' },
  { key: '18', label: 'Harmony: Modulation' },
  { key: '19', label: 'Harmony: Modulation to tonic' },
  { key: '44', label: 'Harmony: Suspension' },
  { key: '41', label: 'Harmony: V-I chord progression' },
  { key: '29', label: 'Listening: Register' },
  { key: '28', label: 'Listening: Timbre' },
  { key: '42', label: 'Melody: Melodies' },
  { key: '24', label: 'Periods: Baroque' },
  { key: '25', label: 'Periods: Classical' },
  { key: '27', label: 'Periods: Modern' },
  { key: '26', label: 'Periods: Romantic' },
  { key: '8', label: 'Rhythm: Common time' },
  { key: '11', label: 'Rhythm: Dotted rhythm' },
  { key: '9', label: 'Rhythm: Duple meter' },
  { key: '31', label: 'Rhythm: Ostinato' },
  { key: '38', label: 'Rhythm: Rests' },
  { key: '10', label: 'Rhythm: Triple meter' },
  { key: '7', label: 'Rhythm: Triplets' },
  { key: '32', label: 'Structure: Call and response' },
  { key: '46', label: 'Structure: Contrary motion' },
  { key: '17', label: 'Structure: Left hand / right hand' },
  { key: '22', label: 'Styles: French Baroque' },
  { key: '23', label: 'Styles: Style brisé' },
  { key: '30', label: 'Techniques: Glissando' },
  { key: '43', label: 'Techniques: Pizzicato' },
  { key: '39', label: 'Techniques: Trills' },
  { key: '37', label: 'None: Octaves' },
];

const levelOptions = [
  { key: '1', label: 'Early Elementary' },
  { key: '2', label: 'Late Elementary' },
  { key: '3', label: 'Middle School' },
  { key: '4', label: 'High School' },
  { key: '5', label: 'College' },
];


class Moments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textFieldValue: props.selectedMoment.text,
    };
    this.handleMomentChange = this.handleMomentChange.bind(this);
    this.handleCheckboxesChange = this.handleCheckboxesChange.bind(this);
    this.handleTextFieldChange = debounce(this.handleTextFieldChange.bind(this), 500);
  }

  componentDidUpdate(prevProps) {
    this.updateTextFieldValueChange(prevProps);
  }

  updateTextFieldValueChange(prevProps) {
    const {
      selectedMoment: {
        text: prevText,
      },
    } = prevProps;
    const {
      selectedMoment: {
        text,
      },
    } = this.props;

    if (text !== prevText) {
      this.setState({
        textFieldValue: text,
      });
    }
  }

  handleMomentChange(data) {
    const {
      changeMoment,
      selectedMomentId,
    } = this.props;

    changeMoment({
      selectedMoment: selectedMomentId,
      data,
    });
  }

  handleTextFieldChange(event) {
    const {
      value,
    } = event.target;
    const {
      changeMoment,
      selectedMomentId,
    } = this.props;

    changeMoment({
      selectedMoment: selectedMomentId,
      data: {
        text: value,
      },
    });
  }

  handleCheckboxesChange(event, key) {
    const {
      selectedMoment,
    } = this.props;
    const {
      value,
    } = event.target;
    const checkboxesOptions = selectedMoment[key];
    const conceptIndex = checkboxesOptions.findIndex((concept) => concept === value);

    if (conceptIndex > -1) {
      this.handleMomentChange({
        [key]: [
          ...checkboxesOptions.slice(0, conceptIndex),
          ...checkboxesOptions.slice(conceptIndex + 1, checkboxesOptions.length),
        ],
      });
    } else {
      this.handleMomentChange({
        [key]: [
          ...checkboxesOptions,
          value,
        ],
      });
    }
  }

  render() {
    const {
      textFieldValue,
    } = this.state;

    const {
      selectedMomentId,
      classes,
      removeMoment,
      selectedMoment: {
        startTime,
        concepts,
        level,
      },
    } = this.props;

    const currentTime = getCurrentTime(startTime);
    const isDisabled = !Number.isInteger(selectedMomentId);

    return (
      <Box display="flex" flexDirection="column" className={classes.wrapper}>
        {isDisabled ? (
          <Box p={2}>
            <Typography color="primary">You don&apos;t have any Moment yet, click on the timeline to add one.</Typography>
          </Box>
        ) : (
          <>
            <Box flexGrow="1">
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" color="primary">
                  Moment {currentTime && (`at ${currentTime}`)}
                </Typography>
              </Box>
              <Box paddingTop={2} paddingBottom={2}>
                <Divider variant="fullWidth" />
              </Box>
              <Box display="flex" flexDirection="column">
                <TextField
                  multiline
                  rows="6"
                  placeholder="Enter text to display here..."
                  margin="normal"
                  variant="filled"
                  value={textFieldValue}
                  onChange={async (event) => {
                    event.persist();
                    this.setState({
                      textFieldValue: event.target.value,
                    });
                    await this.handleTextFieldChange(event);
                  }}
                  disabled={isDisabled}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <FormControl component="fieldset">
                  <FormLabel>Concepts</FormLabel>
                  <FormGroup className={classes.formGroup}>
                    {conceptsOptions.map(({ key, label }) => (
                      <FormControlLabel
                        key={key}
                        control={(
                          <Checkbox
                            color="primary"
                            checked={concepts.includes(key)}
                            onChange={(event) => this.handleCheckboxesChange(event, 'concepts')}
                            value={key}
                            disabled={isDisabled}
                          />
                        )}
                        label={label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>
              <Box display="flex" flexDirection="column">
                <FormControl component="fieldset">
                  <FormLabel>Level</FormLabel>
                  <FormGroup className={classes.formGroup}>
                    {levelOptions.map(({ key, label }) => (
                      <FormControlLabel
                        key={key}
                        control={(
                          <Checkbox
                            color="primary"
                            checked={level.includes(key)}
                            onChange={(event) => this.handleCheckboxesChange(event, 'level')}
                            value={key}
                            disabled={isDisabled}
                          />
                        )}
                        label={label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Button
                className={classes.removeButton}
                variant="contained"
                onClick={() => removeMoment(selectedMomentId)}
                disabled={isDisabled}
              >
                <DeleteIcon />
                Remove moment
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

Moments.propTypes = {
  changeMoment: PropTypes.func.isRequired,
  selectedMomentId: PropTypes.number,
  classes: PropTypes.shape({
    formGroup: PropTypes.string,
    removeButton: PropTypes.string,
    wrapper: PropTypes.string,
  }).isRequired,
  selectedMoment: PropTypes.shape({
    startTime: PropTypes.number,
    concepts: PropTypes.array,
    level: PropTypes.array,
    text: PropTypes.string,
    type: PropTypes.string,
  }),
};

Moments.defaultProps = {
  selectedMomentId: null,
  selectedMoment: {
    startTime: null,
    concepts: [],
    level: [],
    text: '',
    type: 'annotation',
  },
};

const mapStateToProps = (state) => ({
  selectedMomentId: timelineSelectors.getSelectedMoment(state),
  selectedMoment: momentsSelectors.getSelectedMoment(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeMoment,
  removeMoment,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Moments));
