import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import Select from '../../../common/Select';


const afterPlayOptions = [
  { key: 'Fade out', label: 'Fade out' },
  { key: 'Shrink', label: 'Shrink' },
  { key: 'Evaporate', label: 'Evaporate' },
  { key: 'Outline', label: 'Outline' },
  { key: 'Dimmed', label: 'Dimmed' },
];
const onPlayOptions = [
  { key: 'Do nothing', label: 'Do nothing' },
  { key: 'Pop in size', label: 'Pop in size' },
  { key: 'Sudden accent', label: 'Sudden accent' },
  { key: 'Smooth accent', label: 'Smooth accent' },
];
const beforePlayOptions = [
  { key: 'Hidden', label: 'Hidden' },
  { key: 'Fade in', label: 'Fade in' },
  { key: 'Visible', label: 'Visible' },
  { key: 'Outline', label: 'Outline' },
  { key: 'Dimmed', label: 'Dimmed' },
];

function AnimationsPanel({
  afterPlay,
  beforePlay,
  disabled,
  onChange,
  onPlay,
}) {
  return (
    <Grid
      container
      justify="space-between"
      spacing={2}
    >
      <Grid item>
        <Select
          label="After play"
          value={afterPlay}
          options={afterPlayOptions}
          onChange={(data) => onChange({ afterPlay: data })}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <Select
          label="On play"
          value={onPlay}
          options={onPlayOptions}
          onChange={(data) => onChange({ onPlay: data })}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <Select
          label="Before play"
          value={beforePlay}
          options={beforePlayOptions}
          onChange={(data) => onChange({ beforePlay: data })}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

AnimationsPanel.propTypes = {
  afterPlay: PropTypes.string,
  beforePlay: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onPlay: PropTypes.string,
};

AnimationsPanel.defaultProps = {
  afterPlay: null,
  beforePlay: null,
  disabled: false,
  onPlay: null,
};

export default AnimationsPanel;
