import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@material-ui/core';
import LayersList from '../../../../common/LayersList';
import Slider from '../../../../common/Slider';
import CollapsiblePanel from '../../../../common/CollapsiblePanel';


function NotesPanels({
  activePanels,
  values: {
    noteSpacing = {},
    transpose = {},
  },
  onChange,
  onPanelCollapse,
  layers,
  disabled,
  onTriggerClick,
}) {
  return (
    <Box>
      <CollapsiblePanel
        onClick={() => onTriggerClick('noteSpacing')}
        title="Note spacing"
        isExpanded={activePanels.includes('noteSpacing')}
        handleChange={() => onPanelCollapse('noteSpacing')}
        disabled={disabled}
      >
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid item>
            <Slider
              title="Spacing"
              step={10}
              min={0}
              max={100}
              value={noteSpacing.spacing}
              onChange={(spacing) => onChange('noteSpacing', { spacing })}
            />
          </Grid>
          <Grid item>
            <Slider
              title="Transition"
              labelLeft="Fast"
              labelRight="Slow"
              value={noteSpacing.transitionDuration}
              onChange={(transitionDuration) => onChange('noteSpacing', { transitionDuration })}
              valueLabelDisplay="off"
            />
          </Grid>
        </Grid>
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('transpose')}
        title="Transpose"
        isExpanded={activePanels.includes('transpose')}
        handleChange={() => onPanelCollapse('transpose')}
        disabled={disabled}
      >
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid item>
            <Slider
              title="Interval"
              min={-36}
              max={36}
              step={1}
              value={transpose.interval}
              onChange={(interval) => onChange('transpose', { interval })}
              valueLabelFormat={(value) => (value > 0 ? `+${value}` : value)}
            />
          </Grid>
          <Grid item>
            <Slider
              title="Transition"
              labelLeft="Fast"
              labelRight="Slow"
              value={transpose.transitionDuration}
              onChange={(transitionDuration) => onChange('transpose', { transitionDuration })}
              valueLabelDisplay="off"
            />
          </Grid>
          <Grid item>
            <LayersList
              value={transpose.layer}
              label="Layer"
              onChange={(layer) => onChange('transpose', { layer })}
              layers={layers}
            />
          </Grid>
        </Grid>
      </CollapsiblePanel>
    </Box>
  );
}

NotesPanels.propTypes = {
  values: PropTypes.shape({
    noteSpacing: PropTypes.object,
    transpose: PropTypes.object,
  }),
  activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPanelCollapse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onTriggerClick: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
};

NotesPanels.defaultProps = {
  values: {
    noteSpacing: {
      startTime: 0,
      spacing: 5,
      transitionDuration: 5,
    },
    transpose: {
      startTime: 0,
      interval: 0,
      layer: 0,
      transitionDuration: 5,
    },
  },
};

export default NotesPanels;
