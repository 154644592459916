import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { tabsSelectors } from '../store/selectors/tabs';

import ColorPalette from '../views/ColorPalette';
import Animations from '../views/Animations/containers';
import Triggers from '../views/Triggers/containers';
import Moments from '../views/Moments/containers';
import Camera from '../views/Camera/containers';
import AnimationWizard from '../views/AnimationWizard/containers';
import Phrasing from '../views/Phrasing/containers';


const useStyles = makeStyles(() => ({
  detailsPanel: {
    width: 435,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    overflow: 'auto',
  },
}));

function ActiveView({
  activeTab,
  ...props
}) {
  switch (activeTab) {
    case 'color-palette':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ColorPalette {...props} />;
    case 'animations':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Animations {...props} />;
    case 'triggers':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Triggers {...props} />;
    case 'moments':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Moments {...props} />;
    case 'camera':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Camera {...props} />;
    case 'phrasing':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Phrasing {...props} />;
    default:
      return activeTab;
  }
}

function DetailsPanel({
  activeTab,
  onWizardDone,
  isWizardEnabled,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.detailsPanel} p={2}>
      {isWizardEnabled ? (
        <AnimationWizard
          onWizardDone={onWizardDone}
        />
      ) : (
        <ActiveView
          activeTab={activeTab}
        />
      )}
    </Box>
  );
}

DetailsPanel.propTypes = {
  activeTab: PropTypes.string.isRequired,
  isWizardEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  activeTab: tabsSelectors.getActiveTab(state),
});

export default connect(mapStateToProps)(DetailsPanel);
