import React from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TimelineRow from './TimelineRow';
import Pill from '../../common/Pill';
import Ruler from './Ruler';

const useStyles = makeStyles(() => ({
  timeline: {
    position: 'absolute',
    left: 200,
    right: 0,
    flexGrow: 1,
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  scroller: {
    overflowX: 'scroll',
    width: '100%',
  },
  scrollerInner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  note: {
    cursor: 'pointer',
    position: 'absolute',
    '&:hover': {
      opactiy: 0.5,
    },
  },
  timelineRow: {
    '&:hover': {
      background: '#FFF',
    },
  },
}));

const resizeHandleStyles = {
  right: {
    background: '#FFF',
    width: 8,
    height: 8,
    borderRadius: '50%',
    top: 9,
    right: 5,
    opacity: 0.5,
  },
  left: {
    background: '#FFF',
    width: 8,
    height: 8,
    borderRadius: '50%',
    top: 9,
    left: 5,
    opacity: 0.5,
  },
};

function TimelineRows({
  addItem,
  enableResizing,
  handleItemSelect,
  handleOnResizeStop,
  handleOnRowHover,
  handleOnWheel,
  handleMouseLeave,
  isPillPrimary,
  isSelected,
  scrollerRef,
  timelineItems,
  duration,
  disableDragging,
  handleOnDragStop,
}) {
  const classes = useStyles();

  function handleAddItem({ pageX, target }, key) {
    if (target.classList.contains('timeline-row')) {
      const { x } = target.getBoundingClientRect();
      const startTime = pageX - x;

      addItem(key, { startTime });
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.timeline}
      ref={scrollerRef}
      onWheel={handleOnWheel}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        width={duration}
      >
        <Ruler />
        <Box className={classes.scrollerInner}>
          {Object.entries(timelineItems).map(([key, { name, items }], index) => (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <TimelineRow
              className={classes.timelineRow}
              key={name}
              onClick={(event) => handleAddItem(event, key)}
              onMouseOver={() => handleOnRowHover(index)}
              onMouseLeave={handleMouseLeave}
              isSelected={items && !!items.find(({ id }) => isSelected(id))}
            >
              {items.map(({ id, startTime, transitionDuration }) => (isSelected(id) ? (
                <Rnd
                  key={id}
                  disableDragging={disableDragging}
                  bounds="parent"
                  dragAxis="x"
                  enableResizing={{
                    right: enableResizing,
                    left: enableResizing,
                  }}
                  size={{
                    width: transitionDuration * 10,
                    height: 26,
                  }}
                  position={{
                    x: startTime,
                    y: 11,
                  }}
                  default={{
                    x: startTime,
                    y: 11,
                    height: 26,
                    width: transitionDuration * 10,
                  }}
                  onResizeStop={(
                    event,
                    direction,
                    ref,
                    delta,
                    position,
                  ) => handleOnResizeStop(key, ref, position)}
                  onDragStop={(event, direction) => {
                    handleOnDragStop(key, direction, id);
                  }}
                  resizeHandleStyles={resizeHandleStyles}
                >
                  <Pill
                    className={classes.note}
                    onClick={() => handleItemSelect(id, key)}
                    selected={isSelected(id)}
                    width="100%"
                    primary={isPillPrimary}
                  />
                </Rnd>
              ) : (
                <Pill
                  key={id}
                  className={classes.note}
                  onClick={() => handleItemSelect(id, key)}
                  selected={isSelected(id)}
                  width={transitionDuration * 10}
                  left={startTime}
                  primary={isPillPrimary}
                />
              )))}
            </TimelineRow>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

TimelineRows.propTypes = {
  addItem: PropTypes.func.isRequired,
  handleItemSelect: PropTypes.func.isRequired,
  handleOnResizeStop: PropTypes.func.isRequired,
  handleOnRowHover: PropTypes.func.isRequired,
  handleOnWheel: PropTypes.func.isRequired,
  isPillPrimary: PropTypes.string.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  scrollerRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  timelineItems: PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.array,
  }).isRequired,
  enableResizing: PropTypes.bool,
  disableDragging: PropTypes.bool,
  duration: PropTypes.number.isRequired,
};

TimelineRows.defaultProps = {
  enableResizing: true,
  disableDragging: false,
};

export default TimelineRows;
