import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';


function LayersList({
  value,
  onChange,
  layers,
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Layer</FormLabel>
      <RadioGroup value={value} onChange={(event, newValue) => onChange(newValue)}>
        <Grid container>
          {layers.map(({ key, label }) => (
            <Grid item key={key} xs={6}>
              <FormControlLabel
                control={<Radio color="primary" />}
                label={label}
                value={key}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}

LayersList.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

LayersList.defaultProps = {
  value: 0,
};

export default LayersList;
