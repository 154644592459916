export default {
  root: {
    boxShadow: 'none',
  },
  colorPrimary: {
    color: '#A2A1A1',
    backgroundColor: '#FFF',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
};
