import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import Slider from '../../../../common/Slider';
import LayersList from '../../../../common/LayersList';


function LayerSpeedPanel({
  value,
  onChange,
  layers,
}) {
  return (
    <Grid
      container
      direction="column"
      justify="space-around"
      alignItems="stretch"
    >
      <Grid item>
        <Slider
          title="Speed"
          labelLeft="Fast"
          labelRight="Slow"
          value={value.speed}
          onChange={(speed) => onChange({ speed })}
          min={0}
          max={100}
          step={1}
        />
      </Grid>
      <Grid item>
        <Slider
          title="Transition"
          labelLeft="Fast"
          labelRight="Slow"
          value={value.transitionDuration}
          onChange={(transitionDuration) => onChange({ transitionDuration })}
          valueLabelDisplay="off"
        />
      </Grid>
      <Grid item>
        <LayersList
          value={value.layer}
          label="Layer"
          onChange={(layer) => onChange({ layer })}
          layers={layers}
        />
      </Grid>
    </Grid>
  );
}

LayerSpeedPanel.propTypes = {
  value: PropTypes.shape({
    layer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    speed: PropTypes.number,
    transitionDuration: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LayerSpeedPanel;
