import { changeActiveTriggers } from './triggers';
import { changeAnimation } from './animations';
import { changeMoment } from './moments';
import { changeCamera } from './camera';
import triggersSelectors from '../selectors/triggers';

const PREFIX = '[TIMELINE]';

function getExistingTriggerId(triggers, panel, payload) {
  const {
    startTime,
  } = payload;
  const triggerAtSameTime = triggers.find((trigger) => {
    const triggerEndTime = trigger.startTime + trigger.transitionDuration;

    return trigger.startTime + trigger.transitionDuration > startTime
      && trigger.startTime < startTime + 5
      && startTime <= triggerEndTime;
  });
  return triggerAtSameTime ? triggerAtSameTime.id : -1;
}

export const REMOVE_INACTIVE_TRIGGERS = `${PREFIX} REMOVE INACTIVE TRIGGERS`;
export const removeInactiveTriggers = (payload) => (dispatch, getState) => {
  const state = getState();
  const inactiveTriggers = triggersSelectors.getNotActivatedTriggers(state, payload.id);

  dispatch({
    type: REMOVE_INACTIVE_TRIGGERS,
    payload: inactiveTriggers,
  });
};

export const SELECT_TRIGGER = `${PREFIX} SELECT TRIGGER`;
export const selectTrigger = (payload) => (dispatch) => {
  dispatch({
    type: SELECT_TRIGGER,
    payload,
  });

  dispatch(removeInactiveTriggers(payload));
};

export const SELECT_ANIMATION = `${PREFIX} SELECT ANIMATION`;
export const selectAnimation = (payload) => ({
  type: SELECT_ANIMATION,
  payload,
});

export const ADD_TIMELINE_ITEM = `${PREFIX} ADD TIMELINE ITEM`;
export const addTimelineItem = (payload) => ({
  type: ADD_TIMELINE_ITEM,
  payload,
});

export const ADD_TRIGGER = `${PREFIX} ADD TRIGGER`;
const addTrigger = (payload) => ({
  type: ADD_TRIGGER,
  payload,
});

export const createTrigger = (key, payload, panel) => (dispatch, getState) => {
  const sectionName = 'triggers';
  const state = getState();
  const triggers = Object.values(state.triggers.present)
    .map(({ items }) => items)
    .flat();
  const { items } = state.triggers.present[key];
  const existingTriggerId = getExistingTriggerId(items, panel, payload);
  const largestTriggerId = triggers.reduce((largestId, trigger) => {
    if (trigger.id > largestId) {
      return trigger.id;
    }
    return largestId;
  }, -1);
  const id = existingTriggerId > -1
    ? existingTriggerId
    : largestTriggerId + 1;
  const data = { ...payload, id };

  if (existingTriggerId === -1) {
    dispatch(addTimelineItem({
      sectionName,
      key,
      id,
    }));

    dispatch(addTrigger({ id, key, data }));
  }

  dispatch(selectTrigger({ id, key }));

  if (panel) {
    dispatch(changeActiveTriggers({
      id,
      key,
      panel,
    }));
  }
};

export const ADD_ANIMATION = `${PREFIX} ADD ANIMATION`;
const addAnimation = (payload) => ({
  type: ADD_ANIMATION,
  payload,
});

export const createAnimation = (key, payload) => (dispatch, getState) => {
  const {
    animations: {
      present: animations,
    },
  } = getState();
  const sectionName = 'animations';
  const id = Object.values(animations)
    .map((({ items }) => items))
    .flat()
    .length;
  const data = { ...payload, id };

  dispatch(addTimelineItem({
    sectionName,
    key,
    id,
    data,
  }));
  dispatch(addAnimation({ id, key, data }));
  dispatch(selectAnimation(id));
};

export const REMOVE_ACTIVE_TRIGGER = `${PREFIX} REMOVE ACTIVE TRIGGER`;
export const removeActiveTrigger = () => ({
  type: REMOVE_ACTIVE_TRIGGER,
});

export const REMOVE_ACTIVE_ANIMATION = `${PREFIX} REMOVE ACTIVE ANIMATION`;
export const removeActiveAnimation = () => ({
  type: REMOVE_ACTIVE_ANIMATION,
});

export const UPDATE_TIMELINE_ITEM = `${PREFIX} UPDATE_TIMELINE_ITEM`;
export const updateTimelineItem = (payload) => (dispatch) => {
  const {
    activeTab,
  } = payload;

  if (activeTab === 'animations' || activeTab === 'color-palette') {
    dispatch(changeAnimation({
      data: payload.item,
      selectedAnimation: payload.selectedItem,
    }));
  }

  if (activeTab === 'moments') {
    dispatch(changeMoment({
      data: payload.item,
      selectedMoment: payload.selectedItem,
    }));
  }

  if (activeTab === 'camera') {
    dispatch(changeCamera({
      data: payload.item,
      id: payload.selectedItem,
    }));
  }

  dispatch({
    type: UPDATE_TIMELINE_ITEM,
    payload,
  });
};

export const UPDATE_TIMELINE = `${PREFIX} UPDATE_TIMELINE`;
export const updateTimeline = (payload) => ({
  type: UPDATE_TIMELINE,
  payload,
});

export const SET_ANIMATIONS_DATA_STRUCTURE = `${PREFIX} SET_ANIMATIONS_DATA_STRUCTURE`;
export const setAnimationsDataStructure = (payload) => ({
  type: SET_ANIMATIONS_DATA_STRUCTURE,
  payload,
});

export const ADD_MOMENT = `${PREFIX} ADD MOMENT`;
const addMoment = (payload) => ({
  type: ADD_MOMENT,
  payload,
});

export const SELECT_MOMENT = `${PREFIX} SELECT MOMENT`;
export const selectMoment = (payload) => ({
  type: SELECT_MOMENT,
  payload,
});

export const createMoment = (key, payload) => (dispatch, getState) => {
  const { moments } = getState();
  const sectionName = 'moments';
  const id = moments.present.moments.items.length;
  const data = { ...payload, id };

  dispatch(addTimelineItem({
    sectionName,
    key,
    id,
    data,
  }));
  dispatch(addMoment({ id, key, data }));
  dispatch(selectMoment(id));
};

export const ADD_CAMERA = `${PREFIX} ADD CAMERA`;
const addCamera = (payload) => ({
  type: ADD_CAMERA,
  payload,
});

export const SELECT_CAMERA = `${PREFIX} SELECT CAMERA`;
export const selectCamera = (payload) => ({
  type: SELECT_CAMERA,
  payload,
});

export const createCamera = (key, payload) => (dispatch, getState) => {
  const { camera } = getState();
  const sectionName = 'camera';
  const id = camera.present.camera.items.length;
  const data = { ...payload, id };

  dispatch(addTimelineItem({
    sectionName,
    key,
    id,
    data,
  }));
  dispatch(addCamera({ id, key, data }));
  dispatch(selectCamera(id));
};
