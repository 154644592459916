import React from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from '@material-ui/core';
import Slider from '../../../common/Slider';


const useStyles = makeStyles(() => ({
  modalContainer: {
    position: 'absolute !important',
  },
  modal: {
    background: '#FFF',
    width: 500,
    borderRadius: 12,
    padding: 12,
  },
}));

function VisualEmphasisModal({
  isOpened,
  onChange,
  onDoneClick,
  title,
}) {
  const classes = useStyles();
  return (
    <Modal
      open={isOpened}
      hideBackdrop
      className={classes.modalContainer}
      container={document.querySelector('.Player')}
    >
      <Rnd
        enableResizing={false}
        default={{
          x: 250,
          y: 50,
        }}
      >
        <Box className={classes.modal}>
          <Typography variant="h6" color="primary">
            { title }
          </Typography>
          <Box paddingTop={2} paddingBottom={2}>
            <Divider variant="fullWidth" />
          </Box>
          <Box>
            <Slider
              onChange={(intensity) => onChange('noteGlow', { intensity })}
              title="Note glow"
              labelLeft="None"
              labelRight="High"
            />
          </Box>
          <Box>
            <Slider
              onChange={(intensity) => onChange('noteAura', { intensity })}
              title="Note aura"
              labelLeft="None"
              labelRight="High"
            />
          </Box>
          <Box>
            <Slider
              onChange={(intensity) => onChange('godrays', { intensity })}
              title="Godrays"
              labelLeft="None"
              labelRight="High"
            />
          </Box>
          <Box paddingTop={2} paddingBottom={2}>
            <Divider variant="fullWidth" />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={onDoneClick}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Rnd>
    </Modal>
  );
}

VisualEmphasisModal.propTypes = {
  onDoneClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default VisualEmphasisModal;
