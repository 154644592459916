import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ColorsAndShapes from '../components/ColorsAndShapes';
import DetailsSection from '../../../common/DetailsSection';
import * as animationActions from '../../../store/actions/animations';
import * as timelineActions from '../../../store/actions/timeline';
import { animationsSelectors } from '../../../store/selectors/animations';
import { settingsSelectors } from '../../../store/selectors/settings';
import timelineSelectors from '../../../store/selectors/timeline';
import AnimationsPanel from '../components/AnimationsPanel';


const styles = ({ palette }) => ({
  wrapper: {
    height: '100%',
  },
  removeButton: {
    background: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      background: palette.error.dark,
    },
  },
});

class Animations extends Component {
  constructor(props) {
    super(props);

    this.handleAnimationChange = this.handleAnimationChange.bind(this);
    this.handleNoteClick = this.handleNoteClick.bind(this);
  }

  componentDidMount() {
    const {
      Events: {
        NOTE_CLICK,
      },
      EventBus,
      useNoteHoverCursor,
    } = window.MUSICEYES;

    useNoteHoverCursor(true);

    EventBus.on(NOTE_CLICK, this.handleNoteClick);
  }

  componentWillUnmount() {
    const {
      useNoteHoverCursor,
    } = window.MUSICEYES;

    useNoteHoverCursor(false);
  }

  handleNoteClick({ on, trackName }) {
    const {
      animations,
      selectAnimation,
      createAnimation,
    } = this.props;

    const row = Object.values(animations)
      .find(({ name }) => name === trackName);

    const animationInSameTime = row.items
      .find(({ startTime, transitionDuration }) => on >= startTime && on <= startTime + transitionDuration * 10);

    if (animationInSameTime) {
      selectAnimation(animationInSameTime.id);
    } else {
      createAnimation(row.id, {
        startTime: on,
      });
    }
  }

  handleAnimationChange(data) {
    const {
      changeAnimation,
      selectedAnimation,
    } = this.props;

    changeAnimation({
      data,
      selectedAnimation,
    });
  }

  render() {
    const {
      classes,
      currentAnimation: {
        afterPlay,
        beforePlay,
        color,
        onPlay,
        shape,
      },
      noteColors,
      removeAnimation,
      selectedAnimation,
    } = this.props;
    const isDisabled = !Number.isInteger(selectedAnimation);

    return (
      <Box display="flex" flexDirection="column" className={classes.wrapper}>
        {isDisabled ? (
          <Box p={2}>
            <Typography color="primary">You don&apos;t have any Animations yet, click on the timeline to add one.</Typography>
          </Box>
        ) : (
          <>
            <Box flexGrow="1">
              <DetailsSection>
                <ColorsAndShapes
                  color={color}
                  noteColors={noteColors}
                  onChange={this.handleAnimationChange}
                  shape={shape}
                />
              </DetailsSection>
              <DetailsSection>
                <Typography variant="h6" color="primary">
                  Animations
                </Typography>
                <AnimationsPanel
                  beforePlay={beforePlay}
                  onPlay={onPlay}
                  afterPlay={afterPlay}
                  onChange={this.handleAnimationChange}
                />
              </DetailsSection>
            </Box>
            <Box display="flex" flexDirection="column">
              <Button
                className={classes.removeButton}
                variant="contained"
                onClick={() => removeAnimation(selectedAnimation)}
              >
                <DeleteIcon />
                Remove animation
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

Animations.propTypes = {
  animations: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  changeAnimation: PropTypes.func.isRequired,
  removeAnimation: PropTypes.func.isRequired,
  createAnimation: PropTypes.func.isRequired,
  selectAnimation: PropTypes.func.isRequired,
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  selectedAnimation: PropTypes.number,
  currentAnimation: PropTypes.shape({
    afterPlay: PropTypes.string,
    beforePlay: PropTypes.string,
    color: PropTypes.number,
    onPlay: PropTypes.string,
    shape: PropTypes.string,
  }),
  classes: PropTypes.shape({
    removeButton: PropTypes.string,
    wrapper: PropTypes.string,
  }).isRequired,
};

Animations.defaultProps = {
  currentAnimation: {
    afterPlay: null,
    beforePlay: null,
    color: 0,
    onPlay: null,
    shape: 'bar',
  },
  selectedAnimation: null,
};

const mapStateToProps = (state) => ({
  animations: animationsSelectors.getAnimations(state),
  currentAnimation: animationsSelectors.getCurrentAnimation(state),
  noteColors: settingsSelectors.getNoteColors(state),
  selectedAnimation: timelineSelectors.getSelectedAnimation(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeAnimation: animationActions.changeAnimation,
  removeAnimation: animationActions.removeAnimation,
  createAnimation: timelineActions.createAnimation,
  selectAnimation: timelineActions.selectAnimation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Animations));
