import { combineReducers } from 'redux';
import undoable, { excludeAction } from 'redux-undo';
import tabs from './tabs';
import animations from './animations';
import settings from './settings';
import triggers from './triggers';
import timeline from './timeline';
import revisions from './revisions';
import moments from './moments';
import camera from './camera';

import { CHANGE_TAB } from '../actions/tabs';
import {
  SELECT_ANIMATION,
  SELECT_CAMERA,
  SELECT_MOMENT,
  SELECT_TRIGGER,
  SET_ANIMATIONS_DATA_STRUCTURE,
} from '../actions/timeline';

const undoableConfig = {
  neverSkipReducer: false,
  filter: excludeAction([
    CHANGE_TAB,
    SELECT_ANIMATION,
    SELECT_CAMERA,
    SELECT_MOMENT,
    SELECT_TRIGGER,
    SET_ANIMATIONS_DATA_STRUCTURE,
  ]),
};

export default combineReducers({
  tabs,
  animations: undoable(animations, undoableConfig),
  settings: undoable(settings, undoableConfig),
  triggers: undoable(triggers, undoableConfig),
  timeline: undoable(timeline, undoableConfig),
  moments: undoable(moments, undoableConfig),
  camera: undoable(camera, undoableConfig),
  revisions,
});
