import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import {
  AddCircle,
  Delete,
  Link as LinkIcon,
  TouchApp,
} from '@material-ui/icons';

class Phrasing extends Component {
  constructor(props) {
    super(props);

    this.handleAutoLink = this.handleAutoLink.bind(this);
    this.handleRemovePhrasing = this.handleRemovePhrasing.bind(this);
  }

  handleAutoLink() {
    console.log('handleAutoLink');
  }

  handleRemovePhrasing() {
    console.log('handleRemovePhrasing');
  }

  render() {
    return (
      <Box>
        <Box paddingTop={2} paddingBottom={2}>
          <Typography variant="h6" color="primary">
            <TouchApp /> Selecting notes
          </Typography>
          <p>Click a note to select it, click anywhere in the background to unselect.</p>
        </Box>
        <Divider />
        <Box paddingTop={2} paddingBottom={2}>
          <Typography variant="h6" color="primary">
            <AddCircle /> Adding phrasing links
          </Typography>
          <Box paddingTop={2} paddingBottom={2}>
            <Typography>After selecting a note, click another note (that comes after it) to start linking.</Typography>
          </Box>
          <Box paddingBottom={2}>
            <Typography>Keep clicking additional notes to continue linking them.</Typography>
          </Box>
          <Box paddingBottom={2}>
            <Typography>Linked notes must belong to the same track.</Typography>
          </Box>
        </Box>
        <Divider />
        <Box paddingTop={2} paddingBottom={2}>
          <Typography variant="h6" color="primary">
            <LinkIcon /> Auto-linking multiple notes
          </Typography>
          <Typography>Select the first note in the phrase you want to link, then press &ldquo;Auto-link&rdquo; below:</Typography>
          <Box display="flex" justifyContent="center" p={2}>
            <Button variant="contained" onClick={this.handleAutoLink}>
              <LinkIcon /> Auto-link
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box paddingTop={2} paddingBottom={2}>
          <Typography variant="h6" color="primary">
            <Delete /> Removing phrasing links
          </Typography>
          <Typography>First select a linked note, then click the button below to remove the link.</Typography>
          <Box display="flex" justifyContent="center" p={2}>
            <Button variant="contained" onClick={this.handleRemovePhrasing}>
              <Delete /> Remove
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
    );
  }
}

Phrasing.propTypes = {
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Phrasing);
