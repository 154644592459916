export const timelineTriggersOrder = [
  'background',
  'motion',
  'notes',
  'light',
  'other',
];

export const triggerSections = {
  background: [
    'BGCOLOR',
    'HILIGHT',
    'PARTFLD',
    'SHIMMER',
  ],
  motion: [
    'CHNGSPD',
    'LAYRSPD',
    'MAGNETC',
  ],
  notes: [
    'TONESPC',
    'TRANSPS',
  ],
  light: [
    'NOTEGLO',
    'NOTESHF',
    'NOTEAUR',
    'GODRAYS',
  ],
  other: [
    'HDEPAST',
    'MUSTAFF',
  ],
};
