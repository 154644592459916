import {
  Chip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const Pill = withStyles(({ palette }) => ({
  root: ({ selected, left, width, primary }) => ({
    background: selected ? palette[primary][500] : palette.grey[300],
    left,
    width,
    height: 26,
    '&:hover': {
      background: palette[primary][500],
    },
    '&:focus': {
      background: selected ? palette[primary][500] : palette.grey[300],
    },
    '&:active': {
      background: selected ? palette[primary][500] : palette.grey[300],
      boxShadow: 'none',
    },
  }),
}))(Chip);

export default Pill;
