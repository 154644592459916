export default {
  root: {
    minHeight: 70,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&$selected': {
      color: '#03a9f4',
      fontWeight: 700,
    },
  },
};
