import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import BackgroundPanels from '../components/BackgroundPanels';
import MotionPanels from '../components/MotionPanels';
import NotesPanels from '../components/NotesPanels';
import LightPanels from '../components/LightPanels';
import OtherPanels from '../components/Other';
import * as triggersActions from '../../../store/actions/triggers';
import * as timelineActions from '../../../store/actions/timeline';
import { settingsSelectors } from '../../../store/selectors/settings';
import triggersSelectors from '../../../store/selectors/triggers';
import timelineSelectors from '../../../store/selectors/timeline';


const triggerSections = [{
  section: 'background',
  title: 'Background',
  Panel: BackgroundPanels,
}, {
  section: 'motion',
  title: 'Motion',
  Panel: MotionPanels,
}, {
  section: 'notes',
  title: 'Notes',
  Panel: NotesPanels,
}, {
  section: 'light',
  title: 'Light',
  Panel: LightPanels,
}, {
  section: 'other',
  title: 'Other',
  Panel: OtherPanels,
}];

const layerOptions = [
  { key: '0', label: 'All' },
  { key: '1', label: 'Violin I' },
  { key: '2', label: 'Violin II' },
  { key: '3', label: 'Viola' },
  { key: '4', label: 'Celo' },
];

const styles = ({ palette }) => ({
  wrapper: {
    height: '100%',
  },
  scroller: {
    maxHeight: 'calc(100% - 52px)',
    overflowY: 'auto',
    paddingRight: 25,
  },
  removeButtonWrapper: {
    paddingTop: 16,
  },
  removeButton: {
    background: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      background: palette.error.dark,
    },
  },
});

class Triggers extends Component {
  constructor(props) {
    super(props);

    this.isSectionDisabled = this.isSectionDisabled.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePanelCollapse = this.handlePanelCollapse.bind(this);
    this.handleTriggerOnClick = this.handleTriggerOnClick.bind(this);
  }

  isSectionDisabled(section) {
    const {
      selectedTrigger,
      selectedTriggerSection,
    } = this.props;

    return selectedTriggerSection !== section || !Number.isInteger(selectedTrigger);
  }

  handleOnChange(section, trigger, data) {
    const {
      changeTrigger,
      selectedTrigger,
    } = this.props;

    changeTrigger({
      data,
      section,
      selectedTrigger,
      triggerName: trigger,
    });
  }

  handlePanelCollapse(panel, key) {
    const {
      changeActiveTriggers,
      selectedTrigger,
    } = this.props;

    changeActiveTriggers({
      id: selectedTrigger,
      panel,
      key,
    });
  }

  handleTriggerOnClick(section, panel) {
    const {
      selectedTrigger,
      createTrigger,
      timelineItems,
    } = this.props;

    if (this.isSectionDisabled(section)) {
      const item = Object.values(timelineItems)
        .map(({ items }) => items)
        .flat()
        .find(({ id }) => id === selectedTrigger);

      const startTime = item
        ? item.startTime
        : window.MUSICEYES.getCurrentTime() * 100;

      createTrigger(
        section, {
          startTime,
        },
        panel,
      );
    }
  }

  render() {
    const {
      backgroundColors,
      currentTrigger,
      enabledTriggers,
      classes,
      selectedTrigger,
      removeTrigger,
    } = this.props;

    return (
      <Box display="flex" flexDirection="column" flexGrow="1" className={classes.wrapper}>
        <Box display="flex" flexDirection="column" flexGrow="1" className={classes.scroller}>
          <Box flexGrow="1" className={classes.wrapper}>
            {triggerSections.map(({ section, title, Panel }) => (
              <Box key={section}>
                <Typography variant="h6" color="primary">
                  {title}
                </Typography>
                <Panel
                  activePanels={enabledTriggers}
                  backgroundColors={backgroundColors}
                  disabled={this.isSectionDisabled(section)}
                  layers={layerOptions}
                  onChange={(trigger, payload) => this.handleOnChange(section, trigger, payload)}
                  onPanelCollapse={(trigger) => this.handlePanelCollapse(trigger, section)}
                  values={currentTrigger}
                  onTriggerClick={(trigger) => this.handleTriggerOnClick(section, trigger)}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.removeButtonWrapper}>
          <Button
            className={classes.removeButton}
            variant="contained"
            onClick={() => removeTrigger(selectedTrigger)}
            disabled={!Number.isInteger(selectedTrigger)}
          >
            <DeleteIcon />
            Remove triggers
          </Button>
        </Box>
      </Box>
    );
  }
}

Triggers.propTypes = {
  changeTrigger: PropTypes.func.isRequired,
  createTrigger: PropTypes.func.isRequired,
  removeTrigger: PropTypes.func.isRequired,
  changeActiveTriggers: PropTypes.func.isRequired,
  selectedTrigger: PropTypes.number,
  backgroundColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  enabledTriggers: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTriggerSection: PropTypes.string,
  currentTrigger: PropTypes.shape({}),
  timelineItems: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    removeButton: PropTypes.string,
    scroller: PropTypes.string,
  }).isRequired,
};

Triggers.defaultProps = {
  selectedTrigger: null,
  selectedTriggerSection: null,
  currentTrigger: undefined,
};

const mapStateToProps = (state) => ({
  currentTrigger: triggersSelectors.getCurrentTrigger(state),
  enabledTriggers: triggersSelectors.getEnabledTriggers(state),
  backgroundColors: settingsSelectors.getBackgroundColors(state),
  timelineItems: timelineSelectors.getTimelineItems(state),
  selectedTrigger: timelineSelectors.getSelectedTriggerId(state),
  selectedTriggerSection: timelineSelectors.getSelectedTriggerSection(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeActiveTriggers: triggersActions.changeActiveTriggers,
  changeTrigger: triggersActions.changeTrigger,
  removeTrigger: triggersActions.removeTrigger,
  createTrigger: timelineActions.createTrigger,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Triggers));
