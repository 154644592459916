import {
  ADD_CAMERA,
} from '../actions/timeline';

import {
  CHANGE_CAMERA,
  REMOVE_CAMERA,
} from '../actions/camera';

const defaultCamera = {
  startTime: 0,
  transitionDuration: 5,
  target: {
    x: 0,
    y: 0,
    z: 0,
  },
  position: {
    x: 0,
    y: 0,
    z: 0,
    zoom: 0,
    roll: 0,
  },
};

export const initialState = {
  camera: {
    name: 'Camera',
    items: [],
  },
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ADD_CAMERA: {
      const {
        key,
        data,
      } = payload;

      return {
        ...state,
        [key]: {
          ...state[key],
          items: [
            ...state[key].items,
            {
              ...defaultCamera,
              ...data,
            },
          ],
        },
      };
    }
    case CHANGE_CAMERA: {
      const {
        id,
        data,
      } = payload;
      const selectedCameraIndex = state.camera.items.findIndex((camera) => camera.id === id);

      return {
        ...state,
        camera: {
          ...state.camera,
          items: [
            ...state.camera.items.slice(0, selectedCameraIndex),
            {
              ...state.camera.items[selectedCameraIndex],
              ...data,
            },
            ...state.camera.items.slice(selectedCameraIndex + 1, state.camera.items.length),
          ],
        },
      };
    }
    case REMOVE_CAMERA: {
      const removedCameraIndex = state.camera.items.findIndex(({ id }) => id === payload.id);

      return {
        ...state,
        camera: {
          ...state.camera,
          items: [
            ...state.camera.items.slice(0, removedCameraIndex),
            ...state.camera.items.slice(removedCameraIndex + 1, state.camera.items.length),
          ],
        },
      };
    }
    default:
      return state;
  }
};
