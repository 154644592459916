import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';

export default function configureStore(initialState) {
  const middlewares = [ReduxThunk];
  let enhancer;
  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(...middlewares),
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  } else {
    enhancer = compose(applyMiddleware(...middlewares));
  }

  return createStore(
    reducers,
    initialState,
    enhancer,
  );
}
