const PREFIX = '[SETTINGS]';

export const CHANGE_NOTE_COLOR = `${PREFIX} CHANGE NOTE COLOR`;
export const changeNoteColor = (payload) => ({
  type: CHANGE_NOTE_COLOR,
  payload,
});

export const CHANGE_BACKGROUND_COLOR = `${PREFIX} CHANGE BACKGROUND COLOR`;
export const changeBackgroundColor = (payload) => ({
  type: CHANGE_BACKGROUND_COLOR,
  payload,
});

export const UPDATE_SETTINGS = `${PREFIX} UPDATE_SETTINGS`;
export const updateSettings = (payload) => ({
  type: UPDATE_SETTINGS,
  payload,
});
