import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import Slider from '../../../common/Slider';

function NoteMovement({
  createTrigger,
  changeTrigger,
  currentTrigger,
  onStepDone,
}) {
  const [isSlowSpeedTouched, touchSlowSpeed] = useState(false);
  const [isMagneticTouched, touchMagnetic] = useState(false);
  useEffect(() => {
    createTrigger('motion', { startTime: 0 });
  }, []);

  return (
    <Box>
      <Typography>
        How fast do you want the notes to move from right to left? Do you want notes to move with a magnetic effect right when they are played? Try out different options below:
      </Typography>
      <Box>
        <Box>
          <Slider
            title="Scroll speed"
            labelLeft="Slow"
            labelRight="Fast"
            onChange={(speed) => {
              touchSlowSpeed(true);
              changeTrigger({
                section: 'motion',
                data: {
                  speed,
                },
                triggerName: 'scrollSpeed',
                selectedTrigger: currentTrigger.id,
              });
            }}
          />
        </Box>
        <Box>
          <Slider
            title="Magnetic"
            labelLeft="None"
            labelRight="High"
            onChange={(intensity) => {
              touchMagnetic(true);
              changeTrigger({
                section: 'motion',
                data: {
                  intensity,
                },
                triggerName: 'magneticEffect',
                selectedTrigger: currentTrigger.id,
              });
            }}
          />
        </Box>
      </Box>
      {(isSlowSpeedTouched && isMagneticTouched) && (
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={onStepDone}>
            Next step
          </Button>
        </Box>
      )}
    </Box>
  );
}

NoteMovement.propTypes = {
  createTrigger: PropTypes.func.isRequired,
  changeTrigger: PropTypes.func.isRequired,
  currentTrigger: PropTypes.shape({
    id: PropTypes.number,
  }),
  onStepDone: PropTypes.func.isRequired,
};

NoteMovement.defaultProps = {
  currentTrigger: {
    id: null,
  },
};

export default NoteMovement;
