class MusicEyes {
  constructor() {
    this.playbackCallbacksList = [];
    this.initCallbacksList = [];
    this.interval = null;
    this.intervalTime = 25;

    this.handleInitComplete = this.handleInitComplete.bind(this);
    this.handlePlaybackStarted = this.handlePlaybackStarted.bind(this);
    this.handlePlaybackPaused = this.handlePlaybackPaused.bind(this);
  }

  start(intervalTime) {
    const {
      Events: {
        INIT_COMPLETE,
      },
      EventBus,
    } = window.MUSICEYES;

    this.intervalTime = intervalTime || this.intervalTime;

    EventBus.on(INIT_COMPLETE, this.handleInitComplete);
  }

  handleInitComplete() {
    const {
      Events: {
        PLAYBACK_STARTED,
        PLAYBACK_PAUSED,
      },
      EventBus,
    } = window.MUSICEYES;

    this.initCallbacksList.forEach((callback) => {
      callback();
    });

    // eslint-disable-next-line no-underscore-dangle
    if (!Object.keys(EventBus._events).includes(PLAYBACK_STARTED)) {
      EventBus.on(PLAYBACK_STARTED, this.handlePlaybackStarted);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!Object.keys(EventBus._events).includes(PLAYBACK_PAUSED)) {
      EventBus.on(PLAYBACK_PAUSED, this.handlePlaybackPaused);
    }
  }


  handlePlaybackStarted() {
    this.interval = setInterval(() => {
      const visibleRange = window.MUSICEYES.getVisibleRange();

      this.playbackCallbacksList.forEach((callback) => {
        callback(visibleRange);
      });
    }, this.intervalTime);
  }

  handlePlaybackPaused() {
    clearInterval(this.interval);
  }

  addPlaybackCallback(callback) {
    this.playbackCallbacksList.push(callback);
  }

  addInitCallback(callback) {
    this.initCallbacksList.push(callback);
  }
}

export default new MusicEyes();
