import {
  ADD_MOMENT,
} from '../actions/timeline';
import {
  CHANGE_MOMENT,
  UPDATE_MOMENTS,
  REMOVE_MOMENT,
} from '../actions/moments';

const defaultMoment = {
  startTime: 0,
  transitionDuration: 5,
  type: 'annotation',
  concepts: [],
  level: [],
  text: '',
};

export const initialState = {
  moments: {
    name: 'Moments',
    items: [],
  },
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case CHANGE_MOMENT: {
      const {
        selectedMoment,
        data,
      } = payload;

      return {
        ...state,
        moments: {
          ...state.moments,
          items: [
            ...state.moments.items.slice(0, selectedMoment),
            {
              ...state.moments.items[selectedMoment],
              ...data,
            },
            ...state.moments.items.slice(selectedMoment + 1, state.moments.items.length),
          ],
        },
      };
    }
    case ADD_MOMENT: {
      const {
        key,
        data,
      } = payload;

      return {
        ...state,
        [key]: {
          ...state[key],
          items: [
            ...state[key].items,
            {
              ...defaultMoment,
              ...data,
            },
          ],
        },
      };
    }
    case REMOVE_MOMENT: {
      const removedMomentIndex = state.moments.items.findIndex(({ id }) => id === payload);

      return {
        ...state,
        moments: {
          ...state.moments,
          items: [
            ...state.moments.items.slice(0, removedMomentIndex),
            ...state.moments.items.slice(removedMomentIndex + 1, state.moments.items.length),
          ],
        },
      };
    }
    case UPDATE_MOMENTS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
