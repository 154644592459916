import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Slider as MaterialSlider,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import { useId } from 'react-id-generator';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 16,
    marginBottom: 16,
    color: '#7F7F7F',
  },
  sliderWrapper: {
    width: '100%',
  },
  title: {
    fontWeight: 700,
    paddingRight: 8,
    minWidth: 100,
  },
  label: {
    minWidth: 32,
  },
}));

const StyledSlider = withStyles(() => ({
  root: {
    margin: '0 16px',
  },
}))(MaterialSlider);


function Slider({
  onChange,
  value,
  title,
  labelLeft,
  labelRight,
  max,
  min,
  step,
  valueLabelDisplay,
  valueLabelFormat,
  disabled,
}) {
  const [htmlId] = useId();
  const classes = useStyles();
  const [sliderValue, onSliderValueChange] = useState(value);

  useEffect(() => {
    onSliderValueChange(value);
  }, [value]);

  return (
    <Box className={classes.wrapper} display="flex" flexWrap="nowrap">
      <Typography className={classes.title}>
        { title }
      </Typography>
      <Box display="flex" className={classes.sliderWrapper}>
        {labelLeft && (
          <Typography className={classes.label}>
            {labelLeft}
          </Typography>
        )}
        <StyledSlider
          id={htmlId}
          value={sliderValue}
          max={max}
          min={min}
          step={step}
          onChange={(event, data) => {
            event.stopPropagation();
            onSliderValueChange(data);
          }}
          onChangeCommitted={(_, data) => onChange(data)}
          valueLabelDisplay={valueLabelDisplay}
          valueLabelFormat={valueLabelFormat}
          disabled={disabled}
        />
        {labelRight && (
          <Typography className={classes.label}>
            {labelRight}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  valueLabelDisplay: PropTypes.string,
  valueLabelFormat: PropTypes.func,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  value: 0,
  labelLeft: null,
  labelRight: null,
  max: 10,
  min: 0,
  step: 1,
  valueLabelDisplay: 'auto',
  valueLabelFormat: (value) => value,
  disabled: false,
};

export default Slider;
